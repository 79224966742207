<template>
    <div>
        <div class="max-w-xl mx-auto px-4 md:px-6 pt-8">
            <TinyNavigation :prev="{name: 'match.create.3'}" :quit="{name: 'matches' }" />

            <h1 class="text-white font-semibold text-2xl pt-4 pb-4 text-center whitespace-pre-line" v-text="t('page_title')" />
                
            <form @submit.prevent="submitForm">
                
                <div class="grid grid-cols-1 gap-4">
                    <template v-for="question in match_questions" :key="question.id">
                        <template v-if="question.type === `string` || question.type === `integer`">
                            <TextInput 
                                v-model:value="question.answer"
                                :alt-styling="true" 
                                :label="question.question" 
                                :placeholder="question.question" 
                            />
                        </template>
                        <template v-else-if="question.type === `text`">
                            <TextAreaInput 
                                v-model:value="question.answer" 
                                :label="question.question" 
                                :placeholder="question.hint" 
                            />-
                        </template>

                        <div v-else class="text-white">
                            Unknown question type "{{ question.type }}"
                        </div>
                    </template>
                </div>

                <div class="mt-8">
                    <span class="block w-full rounded-md shadow-sm">
                        <button type="submit" class="button w-full">
                            {{ t('complete') }}
                        </button>
                    </span>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import TinyNavigation from "@/components/TinyNavigation";
import { updateMatch, getMatch } from "@/helpers/api/MatchHelper";
import { startSpinner, stopSpinner } from "@/helpers/storage/loadingSpinnerHelper";
import TextInput from "@/components/forms/TextInput";
import TextAreaInput from "@/components/forms/TextAreaInput";
import { getMatchQuestions } from "@/helpers/api/MatchQuestionHelper"
import messages from "@/helpers/storage/MessageHelper";
import useI18n from "@/modules/globalI18n";

export default {
    name: "MatchCreate4",
    props: ['id'],
    components: { 
        TinyNavigation,
        TextInput,
        TextAreaInput,
    },
    setup() {
        return useI18n({
            viewPrefix: "MatchCreate4"
        });
    },
    methods:{
        getMatch() {
            return getMatch(this.id)
                .then(({ match }) => {
                    this.match = match;
                })
                .catch(err => {
                    if (err.status === 404 || err.status === 403){
                        this.match_not_found = true;
                    } else {
                        messages.error(this.t('messages.something_went_wrong', {error: err.message}));
                    }
                });
        },
        async getDynamicQuestions() {
            this.match_questions = await getMatchQuestions();
        },
        rehydrateQuestions() {
            if(!this.match?.questions) {
                return;
            }

            for(const question of this.match_questions) {
                const { key } = question;

                if (key in this.match.questions){
                    question.answer = this.match.questions[key];
                }
            }
        },
        submitForm(){
            startSpinner();

            const keyAnswerEntries = Object.values(this.match_questions).filter(question => question.answer).map(({key, answer}) => [key, answer]);

            const answers = Object.fromEntries(keyAnswerEntries);
            
            const form = {...this.form, questions: JSON.stringify(answers)}

            updateMatch(form, this.id)
                .then(({ match }) => {
                    this.match = match
                    messages.success(this.t('messages.game_saved'))
                    this.$router.push({ name: "match.show", params: { id: match.id } })
                })
                .catch(err => this.errors = err.errors)
                .finally(() => stopSpinner())
        },
    },
    data(){
        return {
            match: undefined,
            match_questions: [],
        };
    },
    watch: {
        match() {
            this.rehydrateQuestions();
        },
        match_questions() {
            this.rehydrateQuestions();
        }
    },
    created(){
        this.getMatch();
        this.getDynamicQuestions();
    },
}
</script>

<style scoped>
.shirt {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 400px;
    height: 300px;
}
</style>