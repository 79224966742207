import axios from "axios";


export function getMatchQuestions() {
    const url = "v1/trainee/match/question";

    return axios.get(url)
        .then(response => response.data.match_questions)
        .catch((err) => {
            throw err.response?.data ?? err;
        });
}